import { SyntheticEvent, useContext, useEffect, useState } from "react";
import { Context } from "../utils/context";
import { getClient, listClients, listFinancialElements, listGoals, listOperationDetails } from "../utils/api";
import Loading from "../components/Loading";
import { Client } from '../model/Client';
import Select from "../components/Select";
import { addMonthsToTimestamp } from '../utils/date-utils';
import OperationsService from "../utils/OperationsService";
import { sortElements } from "../utils/sort-elements";

export default function Clients(): JSX.Element {
    const { setClient, client, setFinancialElements, setOperationDetails, setGoals } = useContext(Context);
    const [loading, setLoading] = useState(true);
    const [clients, setClients] = useState<Client[]>([]);

    useEffect(() => {
        async function setUp() {
            setLoading(true);
            try {
                setClients(await listClients());
            } finally {
                setLoading(false);
            }
        }
        setUp();
    }, [client.id, setClient]);

    if (loading) {
        return (
            <main className='fallback-page'>
                <Loading />
            </main>
        )
    }

    async function selectClient(event: SyntheticEvent) {
        try {
            setLoading(true);
            const target = event.target as HTMLInputElement;
            window.localStorage.setItem('client_id', target.value);
            const today = new Date();
            const UTCtoday = Date.UTC(today.getFullYear(), today.getMonth(), today.getDate()).toString();
            OperationsService.clearCacheAndNotifySubscribers();
            await Promise.all([
                getClient(target.value).then(setClient),
                listOperationDetails(target.value).then(res => setOperationDetails(sortElements(res, [
                    { field: 'order_key', asc: true },
                    { field: 'update_timestamp', asc: false },
                ]))),
                listGoals(target.value).then(res => setGoals(sortElements(res, [
                    { field: 'order_key', asc: true },
                    { field: 'update_timestamp', asc: false },
                ]))),
                listFinancialElements(target.value).then(res => setFinancialElements(sortElements(res, [
                    { field: 'order_key', asc: true },
                    { field: 'update_timestamp', asc: false },
                ]))),
                OperationsService.listOperationsByRange(target.value, addMonthsToTimestamp(UTCtoday, -3), addMonthsToTimestamp(UTCtoday, 1)),
            ]);
        } finally {
            setLoading(false);
        }
    }

    const clientOptions = clients.map(client => ({
        value: client.id,
        alias: `${client.email} (${client.name ? client.name : 'Sin nombre asignado'})`
    }));

    return (
        <main>
            <article className="bg-sf-violet-light">
                <h2>Clientes</h2>
                <Select placeholder='Selecciona un cliente' name='client' value={window.localStorage.getItem('client_id') ?? ''} onChange={selectClient} options={clientOptions} />
            </article>
        </main>
    )
}