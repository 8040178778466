import { useContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Operation } from "../model/Operation";
import { Context } from "../utils/context";
import { useNavigate } from "react-router-dom";
import EmptyList from "../components/EmptyList";
import { useVerticalScrollWithShadow } from "../hooks/VerticalScrollWithShadow";
import { OperationTypes } from "../consts/OperationTypes";
import { sortElements } from "../utils/sort-elements";
import TransactionItem from "../components/TransactionItem";
import { v4 as uuid } from 'uuid';
import { Transaction } from "../model/Transaction";
import TransactionsFilter from "../components/TransactionsFilter";
import OperationsService from "../utils/OperationsService";

export default function Transactions(): JSX.Element {
    const [searchParams] = useSearchParams();
    const { ref, boxShadow, onScrollHandler } = useVerticalScrollWithShadow();
    const { client } = useContext(Context);
    const [operations, setOperations] = useState<Operation[]>([]);
    const [newGroupingId, setNewGroupingId] = useState('');
    const navigate = useNavigate();

    const timestampFrom = searchParams.get('tr_timestamp_from') || searchParams.get('timestamp_from');
    const timestampTo = searchParams.get('tr_timestamp_to') || searchParams.get('timestamp_to');

    useEffect(() => {
        const fetchOperations = () => {
            if (!client.id || !timestampFrom || !timestampTo) return;

            OperationsService.listOperationsByRange(client.id, timestampFrom, timestampTo).then(setOperations);
        };

        fetchOperations();

        return OperationsService.subscribe(fetchOperations);
    }, [client.id, timestampFrom, timestampTo]);

    useEffect(() => {
        setNewGroupingId(uuid());
    }, []);


    const transactionsOperations = operations.filter(operation => (operation?.grouping_id ?? '') !== '');
    sortElements(transactionsOperations, [
        { field: 'timestamp', asc: false },
        { field: 'update_timestamp', asc: false },
    ]);

    const transactionsMap = new Map<string, Operation[]>();
    transactionsOperations
        .filter(operation => searchParams.get('tr_type') === null || (searchParams.get('tr_type') === OperationTypes.OUTFLOW.key && operation.amount?.includes('-')) || (searchParams.get('tr_type') === OperationTypes.INFLOW.key && !operation.amount?.includes('-')))
        .filter(operation => searchParams.get('tr_currency_code') === null || operation.currency_code === searchParams.get('tr_currency_code'))
        .filter(operation => searchParams.get('tr_financial_element_id') === null || operation.financial_element_id === searchParams.get('tr_financial_element_id'))
        .filter(operation => searchParams.get('tr_timestamp_from') === null || (operation.timestamp ?? '') >= (searchParams.get('tr_timestamp_from') ?? ''))
        .filter(operation => searchParams.get('tr_timestamp_to') === null || (operation.timestamp ?? '') < (searchParams.get('tr_timestamp_to') ?? ''))
        .forEach(operation => transactionsMap.set(operation.grouping_id ?? '', [...(transactionsMap.get(operation.grouping_id ?? '') ?? []), operation]));

    const transactions: Transaction[] = [];
    transactionsMap.forEach((operationGroup, grouping_id) => transactions.push({
        grouping_id: grouping_id,
        timestamp: operationGroup.map(operation => operation.timestamp)
            .reduce((previousValue, currentValue) => (currentValue ?? '') < (previousValue ?? '') ? currentValue : previousValue),
        update_timestamp: operationGroup.map(operation => operation.update_timestamp)
            .reduce((previousValue, currentValue) => (currentValue ?? '') > (previousValue ?? '') ? currentValue : previousValue),
        operations: operationGroup
    }));
    sortElements(transactions, [
        { field: 'timestamp', asc: false },
        { field: 'update_timestamp', asc: false },
    ]);

    return (
        <main>
            <button className='button-aux w-fit p-1 col-span-full' onClick={() => navigate(`/operations?${searchParams}`)}>
                <svg xmlns="http://www.w3.org/2000/svg" className="mr-2 h-2.5 w-2.5" viewBox="0 0 24 24"><path fill="currentColor" d="m3.55 12l7.35 7.35q.375.375.363.875t-.388.875q-.375.375-.875.375t-.875-.375l-7.7-7.675q-.3-.3-.45-.675T.825 12q0-.375.15-.75t.45-.675l7.7-7.7q.375-.375.888-.363t.887.388q.375.375.375.875t-.375.875L3.55 12Z" /></svg>
                <div>Volver a Ingresos y egresos</div>
            </button>
            <article className="p-0 space-y-0 h-fit">
                <div className="flex w-full flex-row justify-between items-center p-3">
                    <h2>Transacciones internas</h2>
                    <button className="button-aux flex space-x-1 items-center" onClick={() => navigate(`/transactions/${newGroupingId}?${searchParams}`)}>
                        <p>Nueva</p>
                        <svg xmlns="http://www.w3.org/2000/svg" className="ml-2 h-3.5" viewBox="0 0 24 24"><path fill="currentColor" d="M11 13H6q-.425 0-.713-.288T5 12q0-.425.288-.713T6 11h5V6q0-.425.288-.713T12 5q.425 0 .713.288T13 6v5h5q.425 0 .713.288T19 12q0 .425-.288.713T18 13h-5v5q0 .425-.288.713T12 19q-.425 0-.713-.288T11 18v-5Z" /></svg>
                    </button>
                </div>
                <TransactionsFilter operations={transactionsOperations} />
                <div onScroll={onScrollHandler} ref={ref} className={`px-4 max-h-96 rounded-b-2xl overflow-auto ${boxShadow}`}>
                    <ul className="divide-y divide-sf-black border-t border-sf-black">
                        {transactions.length > 0
                            ? transactions.map(transaction => <TransactionItem key={transaction.grouping_id} transaction={transaction} />)
                            : <EmptyList message="No hay transacciones internas." />
                        }
                    </ul>
                </div>
            </article>
        </main>
    );
}
