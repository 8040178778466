import { useCallback, useContext, useEffect, useState } from "react";
import { Context } from "../utils/context";
import { listGoals } from "../utils/api";
import { Goal } from "../model/Goal";
import Loading from "../components/Loading";
import { useNavigate } from "react-router-dom";
import EmptyList from "../components/EmptyList";
import GoalStatus from "../components/GoalStatus";
import { useCurrencyByCode } from "../hooks/CurrencyFromCode";
import { getInteger, getDecimal } from '../utils/number-utils';
import { sortElements } from "../utils/sort-elements";

interface GoalItemProps {
    goal: Goal
}

function GoalItem(props: GoalItemProps): JSX.Element {
    const { operationDetails } = useContext(Context);
    const goal = props.goal;
    const navigate = useNavigate();
    const { getSign } = useCurrencyByCode();

    return (
        <div className="py-2 cursor-pointer" onClick={() => navigate(`/goals/${goal.goal_id}`)}>
            {operationDetails.find(od => od.goal_id === goal.goal_id) === undefined && <div className='bg-sf-yellow-light w-fit mb-1 rounded-lg p-0.5 ring-1 ring-inset ring-sf-red-medium mr-2'>
                <svg xmlns="http://www.w3.org/2000/svg" className='w-5 text-sf-red-medium' viewBox="0 0 256 256"><g fill="currentColor"><path d="M215.46 216H40.54c-12.62 0-20.54-13.21-14.41-23.91l87.46-151.87c6.3-11 22.52-11 28.82 0l87.46 151.87c6.13 10.7-1.79 23.91-14.41 23.91Z" opacity=".2" /><path d="M236.8 188.09L149.35 36.22a24.76 24.76 0 0 0-42.7 0L19.2 188.09a23.51 23.51 0 0 0 0 23.72A24.35 24.35 0 0 0 40.55 224h174.9a24.35 24.35 0 0 0 21.33-12.19a23.51 23.51 0 0 0 .02-23.72Zm-13.87 15.71a8.5 8.5 0 0 1-7.48 4.2H40.55a8.5 8.5 0 0 1-7.48-4.2a7.59 7.59 0 0 1 0-7.72l87.45-151.87a8.75 8.75 0 0 1 15 0l87.45 151.87a7.59 7.59 0 0 1-.04 7.72ZM120 144v-40a8 8 0 0 1 16 0v40a8 8 0 0 1-16 0Zm20 36a12 12 0 1 1-12-12a12 12 0 0 1 12 12Z" /></g></svg>
            </div>}
            <div className='flex space-x-1 justify-between font-bold'>
                <p className="truncate pr-4 self-center">{goal.title}</p>
                {goal.currency && goal.amount &&
                    <div className="flex min-w-fit self-center">
                        <p className="mx-1 font-bold">{getSign(goal.currency)}</p>
                        <p className="font-bold">{getInteger(goal.amount ?? '')}</p>
                        <p className="font-bold text-[11px]/[16px] ml-[1px]">{getDecimal(goal.amount ?? '')}</p>
                    </div>
                }
            </div>
            <div className="flex w-full py-1">
                <div className="flex min-h-full text-sm self-center w-full">{goal.term}</div>
                <GoalStatus status={goal.status ?? ''} />
            </div>
            <p className="secondary">{goal.description}</p>
        </div>
    );
}

export default function Goals(): JSX.Element {
    const { client, goals, setGoals } = useContext(Context);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    const setUp = useCallback(async () => {
        setLoading(true);
        try {
            const results = await listGoals(client.id ?? '');
            sortElements(results, [
                { field: 'order_key', asc: true },
                { field: 'update_timestamp', asc: false },
            ]);
            setGoals(results);
        } finally {
            setLoading(false);
        }
    }, [client.id, setGoals]);

    useEffect(() => { setUp() }, [setUp]);

    if (loading) return <Loading />

    const goalsToDisplay = [
        ...goals.filter(goal => goal.status === 'pending'),
        ...goals.filter(goal => goal.status === 'done'),
    ];

    return (
        <main>
            <article className="h-fit pb-2 bg-sf-green-light">
                <div className="flex w-full flex-row justify-between items-center">
                    <h2>Objetivos</h2>
                    <button className="button-aux flex space-x-1 items-center" onClick={() => navigate('/goals/new')}>
                        <p>Nuevo</p>
                        <svg xmlns="http://www.w3.org/2000/svg" className="ml-2 h-3.5" viewBox="0 0 24 24"><path fill="currentColor" d="M11 13H6q-.425 0-.713-.288T5 12q0-.425.288-.713T6 11h5V6q0-.425.288-.713T12 5q.425 0 .713.288T13 6v5h5q.425 0 .713.288T19 12q0 .425-.288.713T18 13h-5v5q0 .425-.288.713T12 19q-.425 0-.713-.288T11 18v-5Z" /></svg>
                    </button>
                </div>
                <div className="divide-y divide-sf-black border-t border-sf-black">
                    {goalsToDisplay.length > 0 ?
                        goalsToDisplay.map(goal => <GoalItem key={goal.goal_id} goal={goal} />)
                        :
                        <EmptyList message="Aún no se cargaron objetivos." />
                    }
                </div>
            </article>
        </main>
    );
}
