import config from './config.json';

const awsmobile = {
    "aws_project_region": config.aws_region.value,
    "aws_cognito_identity_pool_id": config.coaching_platform_user_identity_pool_id.value,
    "aws_cognito_region": config.aws_region.value,
    "aws_user_pools_id": config.coaching_platform_user_pools_id.value,
    "aws_user_pools_web_client_id": config.coaching_platform_user_pools_client_id.value,
    "oauth": {
        "domain": config.coaching_platform_oauth_domain.value,
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": config.coaching_platform_redirect_sign_in.value,
        "redirectSignOut": config.coaching_platform_redirect_sign_out.value,
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_cloud_logic_custom": [
        {
            "name": "clients_api",
            "endpoint": config.clients_api_endpoint.value,
            "region": config.aws_region.value
        }
    ]
};

export default awsmobile;
